<template>
  <div>
    <v-dialog
      v-model="dialog"
      persistent
      transition="dialog-transition"
      max-width="760"
      :fullscreen="$vuetify.breakpoint.xs"
      scrollable
    >
      <v-card>
        <div class="primary white--text">
          <v-card-title>
            แก้ไขข้อมูล
            <v-btn icon class="ml-auto white--text" @click="close">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
        </div>
        <v-divider></v-divider>
        <v-card-text v-if="scenarios[0]">
          <v-form ref="form">
            <div
              v-for="(scenario, indexScenario) in scenarios"
              :key="indexScenario"
            >
              <v-divider class="mt-4 pa-0" v-if="indexScenario > 0"></v-divider>
              <v-row class="mt-4">
                <v-col cols="6">
                  <v-row align="center">
                    <v-col
                      :class="$vuetify.breakpoint.xs && 'pb-0'"
                      cols="12"
                      sm="4"
                      >Scenario</v-col
                    >
                    <v-col
                      :class="$vuetify.breakpoint.xs && 'pt-0'"
                      cols="12"
                      sm="8"
                    >
                      <v-text-field
                        v-model="editForm[indexScenario].id"
                        class="right-input"
                        outlined
                        dense
                        readonly
                        hide-details="auto"
                        background-color="#e1e1e1"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="6">
                  <v-row align="center">
                    <v-col
                      sm="4"
                      cols="12"
                      :class="$vuetify.breakpoint.xs && 'pb-0'"
                    >
                      Condition Rating
                      <span class="red--text"> * </span>
                    </v-col>
                    <v-col
                      :class="$vuetify.breakpoint.xs && 'pt-0'"
                      cols="12"
                      sm="8"
                    >
                      <v-text-field
                        v-model="editForm[indexScenario].condition_value"
                        class="right-input"
                        type="number"
                        min="0"
                        outlined
                        dense
                        hide-details="auto"
                        :rules="[rules.required]"
                      >
                        <template v-slot:prepend-inner>
                          <div
                            class="d-flex align-center justify-center"
                            style="
                              width: 40px;
                              height: 40px;
                              margin: -8px 4px 0 -12px;
                              background-color: #e1e1e1;
                              border-radius: 4px 0 0 4px;
                            "
                          >
                            {{ editForm[indexScenario].compare_operator }}
                          </div>
                        </template>
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12">
                  <v-row align="center">
                    <v-col
                      :class="$vuetify.breakpoint.xs && 'pb-0'"
                      cols="12"
                      sm="2"
                      >ความเสียหาย
                    </v-col>
                    <v-col
                      :class="$vuetify.breakpoint.xs && 'pt-0'"
                      cols="12"
                      sm="10"
                    >
                      <v-text-field
                        v-model="editForm[indexScenario].condition_text"
                        outlined
                        dense
                        hide-details="auto"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <div class="primary--text mt-4">
                <b>การทำนาย Condition Rating</b>
              </div>
              <v-row class="mt-2 pa-0" align="center">
                <v-col
                  v-for="(form, index) in formEditConditionField"
                  :key="index"
                  :cols="12"
                  :sm="form.colssm"
                  v-show="
                    editForm[indexScenario].id !== '5'
                      ? true
                      : form.model === 'description'
                  "
                >
                  <v-row align="center">
                    <v-col
                      :class="$vuetify.breakpoint.xs && 'pb-0'"
                      :sm="form.colstext"
                      cols="12"
                    >
                      {{ form.name }}
                      <span v-if="form.isrequired" class="red--text"> * </span>
                    </v-col>
                    <v-col
                      :class="$vuetify.breakpoint.xs && 'pt-0'"
                      :sm="form.colsfield"
                      cols="12"
                    >
                      <v-text-field
                        v-if="form.type === 'text'"
                        v-model="editForm[indexScenario][form.model]"
                        outlined
                        dense
                        hide-details="auto"
                        background-color="white"
                      ></v-text-field>
                      <v-text-field
                        v-else-if="form.type === 'number'"
                        v-model="editForm[indexScenario][form.model]"
                        class="right-input"
                        type="number"
                        placeholder="0"
                        min="0"
                        outlined
                        dense
                        hide-details="auto"
                        background-color="white"
                        :rules="
                          form.isrequired && editForm[indexScenario].id !== '5'
                            ? [rules.required]
                            : []
                        "
                      ></v-text-field>
                      <v-textarea
                        v-else-if="form.type === 'textarea'"
                        v-model="editForm[indexScenario][form.model]"
                        class="right-input"
                        type="number"
                        min="0"
                        outlined
                        dense
                        hide-details="auto"
                        background-color="white"
                      ></v-textarea>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </div>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="green white--text"
            elevation="0"
            style="width: 140px"
            @click="confirm()"
          >
            บันทึก
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <ConfirmDialog
      v-model="isConfirmEditDialog"
      :dialog-title="'ยืนยันการแก้ไขข้อมูล ?'"
      :isLoading="isLoading"
      @confirm="edit()"
      @cancel="closeConfirm()"
    />
  </div>
</template>

<script>
import ConfirmDialog from '@/components/dialog/confirmDialog.vue'
export default {
  components: {
    ConfirmDialog
  },
  props: {
    value: Boolean,
    editForecast: Object,
    scenarios: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  watch: {
    value (newVal, oldVal) {
      if (newVal) {
        for (const index in this.scenarios) {
          this.editForm[index] = { ...this.scenarios[index] }
        }
      }
      this.dialog = newVal
    }
  },
  data: () => ({
    dialog: false,
    editForm: [{}, {}, {}, {}, {}],
    formEditField: [
      {
        name: 'Scenario',
        model: 'scenario',
        colstext: 4,
        colsfield: 8,
        type: 'number'
      },
      {
        name: 'Condition Rating',
        model: 'condition_value',
        colstext: 4,
        colsfield: 8,
        type: 'number'
      },
      {
        name: 'ความเสียหาย',
        model: 'condition_text',
        colstext: 4,
        colsfield: 8,
        type: 'text'
      }
    ],
    formEditConditionField: [
      {
        name: 'ภายใน (ปี)',
        model: 'in_year',
        colssm: 6,
        colstext: 4,
        colstextsm: 2,
        colsfield: 8,
        type: 'number',
        isrequired: true
      },
      {
        name: 'จะลดลงเหลือ',
        model: 'condition_predict_value',
        colssm: 6,
        colstext: 4,
        colsfield: 8,
        type: 'number',
        isrequired: true
      },
      {
        name: 'ความเสียหาย',
        model: 'condition_predict_text',
        colssm: 12,
        colstext: 2,
        colsfield: 10,
        type: 'text'
      },
      {
        name: 'ค่า S.R. จะลดลงอีก (%)',
        model: 'reduce_percent',
        colssm: 6,
        colstext: 4,
        colsfield: 8,
        type: 'number',
        isrequired: true
      },
      {
        name: 'การดำเนินการ',
        model: 'description',
        colssm: 12,
        colstext: 2,
        colsfield: 10,
        type: 'textarea'
      }
    ],
    isConfirmEditDialog: false,
    rules: {
      required: (value) => !!value || 'กรุณากรอกข้อมูล'
    },
    isLoading: false
  }),
  methods: {
    close () {
      this.$emit('close')
    },
    closeConfirm () {
      this.isConfirmEditDialog = false
    },
    confirm () {
      if (this.$refs.form.validate()) {
        this.isConfirmEditDialog = true
      } else {
        const invalidField = this.$refs.form.inputs.find((e) => !e.valid)
        if (invalidField) {
          invalidField.$el.scrollIntoView({
            behavior: 'smooth',
            block: 'center'
          })
        }
      }
    },
    async edit () {
      this.isLoading = true
      const payload = {
        ids: [],
        condition_values: [],
        condition_texts: [],
        condition_predict_values: [],
        condition_predict_texts: [],
        descriptions: [],
        reduce_percents: [],
        in_years: []
      }
      for (const form of this.editForm) {
        payload.ids.push(form.id)
        payload.condition_values.push(form.condition_value)
        payload.condition_texts.push(form.condition_text)
        payload.descriptions.push(form.description || '')
        payload.reduce_percents.push(form.reduce_percent || '')
        if (form.id === '5') {
          payload.in_years.push(null)
          payload.condition_predict_values.push(null)
          payload.condition_predict_texts.push(null)
        } else {
          payload.in_years.push(form.in_year)
          payload.condition_predict_values.push(form.condition_predict_value)
          payload.condition_predict_texts.push(form.condition_predict_text)
        }
      }
      const result = await this.$store.dispatch(
        'setting/setSettingPredictionDamage',
        payload
      )
      this.isLoading = false
      this.closeConfirm()
      if (result) {
        this.$emit('update')
      }
    }
  }
}
</script>

<style scoped>
.right-input >>> input {
  text-align: right;
}
</style>
