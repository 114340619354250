<template>
  <div class="pa-4 mt-3 fill-height overflow-y-auto">
    <div class="d-flex">
      <h2 class="primary--text font-weight-regular">
        ตั้งค่า : การทำนายความเสียหาย
      </h2>
      <v-btn
        v-if="$store.getters.manage_settings"
        class="green white--text ml-4"
        :disabled="isLoading"
        @click="openEditForecastDialog(predictTable)"
      >
        <v-icon left>mdi-pencil-outline</v-icon>แก้ไข</v-btn
      >
    </div>
    <v-card class="primary-border mt-4" outlined>
      <v-card-text class="ma-0 my__v-card-text pa-4">
        <div
          class="d-flex"
          :class="$vuetify.breakpoint.smAndUp ? 'justify-end' : ''"
        ></div>
        <div>
          <v-simple-table>
            <template v-if="$vuetify.breakpoint.smAndUp" v-slot:default>
              <thead class="primary">
                <tr>
                  <th rowspan="2" class="text-left white--text">Scenario</th>
                  <th rowspan="2" class="text-center white--text">
                    Condition Rating
                  </th>
                  <th rowspan="2" class="text-left white--text">ความเสียหาย</th>
                  <th colspan="5" class="text-center white--text">
                    การทำนาย Condition Rating
                  </th>
                </tr>
                <tr>
                  <th class="text-center white--text">ภายใน (ปี)</th>
                  <th class="text-center white--text">จะลดลงเหลือ</th>
                  <th class="text-left white--text">ความเสียหาย</th>
                  <th class="text-center white--text">
                    ค่า S.R. <br />จะลดลงอีก
                  </th>
                  <th class="text-left white--text">การดำเนินการ</th>
                </tr>
              </thead>
              <tr v-if="isLoading" class="pa-0 ma-0">
                <td colspan="8" class="pa-0 ma-0">
                  <v-progress-linear indeterminate></v-progress-linear>
                </td>
              </tr>
              <tbody>
                <tr
                  v-for="(prediction, predictionKey) in predictTable"
                  :key="predictionKey"
                >
                  <td class="text-center">{{ prediction.id }}</td>
                  <td class="text-center">
                    {{ prediction.compare_operator }}
                    {{ prediction.condition_value }}
                  </td>
                  <td>{{ prediction.condition_text }}</td>
                  <td v-if="prediction.id != 5" class="text-center">
                    {{ prediction.in_year }}
                  </td>
                  <td v-if="prediction.id != 5" class="text-center">
                    {{ prediction.condition_predict_value }}
                  </td>
                  <td v-if="prediction.id != 5">
                    {{ prediction.condition_predict_text }}
                  </td>
                  <td v-if="prediction.id != 5" class="text-center">
                    {{ prediction.reduce_percent }}%
                  </td>
                  <td :colspan="prediction.id == 5 ? 6 : 1">
                    {{ prediction.description }}
                  </td>
                </tr>
              </tbody>
            </template>
            <template v-else v-slot:default>
              <div v-if="isLoading" class="d-flex justify-center">
                <v-progress-circular
                  color="grey lighten-2"
                  indeterminate
                ></v-progress-circular>
              </div>
              <tbody v-for="(prediction, index) in predictTable" :key="index">
                <tr class="mt-4">
                  <td class="pa-2">
                    <table style="width: 100%">
                      <tbody>
                        <tr>
                          <th class="text-left py-2">Scenario</th>
                          <td class="text-right">{{ prediction.id }}</td>
                        </tr>
                        <tr>
                          <th class="text-left py-2">Condition Rating</th>
                          <td class="text-right">
                            {{ prediction.compare_operator }}
                            {{ prediction.condition_value }}
                          </td>
                        </tr>
                        <tr>
                          <th class="text-left py-2">ความเสียหาย</th>
                          <td class="text-right">
                            {{ prediction.condition_text }}
                          </td>
                        </tr>
                        <tr v-if="prediction.id != 5">
                          <th class="text-left py-2">ภายใน (ปี)</th>
                          <td class="text-right">{{ prediction.in_year }}</td>
                        </tr>
                        <tr v-if="prediction.id != 5">
                          <th class="text-left py-2">
                            Condition Rating <br />
                            จะลดลงเหลือ
                          </th>
                          <td class="text-right">
                            {{ prediction.condition_predict_value }}
                          </td>
                        </tr>
                        <tr v-if="prediction.id != 5">
                          <th class="text-left py-2">ความเสียหาย</th>
                          <td class="text-right">
                            {{ prediction.condition_predict_text }}
                          </td>
                        </tr>
                        <tr v-if="prediction.id != 5">
                          <th class="text-left py-2">ค่า S.R. จะลดลงอีก</th>
                          <td class="text-right">
                            {{ prediction.reduce_percent }}%
                          </td>
                        </tr>
                        <tr v-if="prediction.id != 5">
                          <th class="text-left py-2">ค่า S.R. คงเหลือ</th>
                          <td class="text-right py-2">
                            <!-- {{ parseFloat(predictTable.value.sr).toFixed(2) }} -->
                          </td>
                        </tr>
                        <tr>
                          <th class="text-left my-4">การดำเนินการ</th>
                          <td class="text-right">
                            {{ prediction.description }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
                <v-divider></v-divider>
              </tbody>
            </template>
          </v-simple-table>
        </div>
      </v-card-text>
    </v-card>
    <EditForecastDialog
      v-model="editForecastDialog"
      :scenarios="predictTable"
      @update="updateForecast"
      @close="editForecastDialog = false"
    />
  </div>
</template>

<script>
import EditForecastDialog from '@/components/settingpage/dialog/EditForecastDialog.vue'
export default {
  components: {
    EditForecastDialog
  },
  data: () => ({
    headers: [
      {
        text: 'scenario',
        align: 'center',
        sortable: false,
        value: 'id',
        class: 'primary rounded-tl-lg white--text'
      },
      {
        text: 'condition',
        align: 'center',
        sortable: false,
        value: 'compare_operator',
        class: 'primary white--text'
      },
      {
        text: 'ความเสียหาย',
        align: 'center',
        sortable: false,
        value: 'condition_text',
        class: 'primary white--text'
      },
      {
        text: 'ภายใน (ปี)',
        align: 'center',
        sortable: false,
        value: 'in_year',
        class: 'primary white--text'
      },
      {
        text: 'จะลดลงเหลือ',
        align: 'center',
        sortable: false,
        value: 'condition_predict_value',
        class: 'primary white--text'
      },
      {
        text: 'ความเสียหาย',
        align: 'center',
        sortable: false,
        value: 'condition_predict_text',
        class: 'primary white--text'
      },
      {
        text: ' ค่า S.R. จะลดลงอีก',
        align: 'center',
        sortable: false,
        value: 'reduce_percent',
        class: 'primary white--text'
      },
      {
        text: 'ค่า S.R. คงเหลือ',
        align: 'center',
        sortable: false,
        value: 'condition_text',
        class: 'primary white--text'
      },
      {
        text: 'การดำเนินการ',
        align: 'center',
        sortable: false,
        value: 'description',
        class: 'primary white--text'
      }
    ],
    items: [],
    predictTable: [],
    editForecast: [],
    editForecastDialog: false,
    isLoading: false
  }),
  mounted () {
    this.getSettingPredictionDamage()
  },
  methods: {
    async getSettingPredictionDamage () {
      this.isLoading = true
      this.predictTable = await this.$store.dispatch(
        'setting/getSettingPredictionDamage'
      )
      this.isLoading = false
    },
    openEditForecastDialog () {
      this.editForecastDialog = true
    },
    updateForecast () {
      this.getSettingPredictionDamage()
      this.editForecastDialog = false
    }
  }
}
</script>

<style scoped>
.primary-border {
  border: 1px solid #c22d47;
}
.background-grey {
  background-color: #f9f9f9;
}
.detail {
  font-family: 'Sarabun';
}
th {
  border: 0.5px solid rgba(255, 255, 255, 0.5);
  white-space: nowrap;
}
.forcast-background {
  background-color: #fff9cc;
}
.forcast-background:hover {
  background-color: #fff9cc !important;
}
</style>
